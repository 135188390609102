<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card>
      <v-card-title class="font-rubik">Selecciona la teva comunitat</v-card-title>
      <v-card-text>
        <p class="mb-4">Hem detectat que no ets de Catalunya. Selecciona la teva comunitat per accedir a sele.es</p>
        <v-list>
          <v-list-item
            v-for="community in communities"
            :key="community.id"
            @click="selectCommunity(community)"
            :class="{'selected-community': selectedCommunity?.id === community.id}"
          >
            <div class="d-flex align-center justify-start" style="width: 100%;">
              <img
                :src="`https://sele.es:3334/api/images/communities/${community.id}.png`"
                :alt="`Logo ${community.name}`"
                class="rounded mr-2"
                style="width: 20px; height: 20px; object-fit: contain; max-width: 28px; max-height: 20px;"
              />
              <v-list-item-content class="d-flex align-center" style="flex: 1;">
                <v-list-item-title class="ml-2 font-rubik"
                  :style="`color: ${$vuetify.theme.dark ? 'white' : 'black'} !important;`"
                >{{ community.name }}</v-list-item-title>
              </v-list-item-content>
              <v-tooltip bottom color="black" small>
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-if="['madrid', 'catalunya'].includes(community.id)"
                    color="transparent"
                    label
                    v-on="on"
                  >
                    ✅
                  </v-chip>
                </template>
                Comunitats disponibles
              </v-tooltip>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommunityDialog',
  data() {
    return {
      url: process.env.VUE_APP_AXIOS_URI,
      dialog: false,
      communities: [
        { id: 'madrid', name: 'Comunidad de Madrid' },
        { id: 'catalunya', name: 'Catalunya' },
        { id: 'andalucia', name: 'Andalucía' },
        { id: 'aragon', name: 'Aragón' },
        { id: 'asturias', name: 'Asturias' },
        { id: 'canarias', name: 'Canarias' },
        { id: 'cantabria', name: 'Cantabria' },
        { id: 'castillaMancha', name: 'Castilla-La Mancha' },
        { id: 'castillaLeon', name: 'Castilla y León' },
        { id: 'valencia', name: 'Comunidad Valenciana' },
        { id: 'extremadura', name: 'Extremadura' },
        { id: 'galicia', name: 'Galicia' },
        { id: 'balears', name: 'Islas Baleares' },
        { id: 'rioja', name: 'La Rioja' },
        { id: 'murcia', name: 'Región de Murcia' },
        { id: 'navarra', name: 'Navarra' },
        { id: 'paisVasco', name: 'País Vasco' },
        { id: 'uned', name: 'UNED' }
      ],
      selectedCommunity: null
    }
  },
  async mounted() {
    await this.autoSelectCommunity();
  },
  methods: {
    async autoSelectCommunity() {
      try {
        const response = await fetch(`${process.env.VUE_APP_AXIOS_URI}/detect-community`);
        const data = await response.json();
        
        if (data.community && data.community !== 'catalunya') {
          const community = this.communities.find(c => c.id === data.community);
          if (community) {
            this.dialog = true;
          } else {
            // Si no trobem la comunitat específica, mostrem el diàleg igualment
            this.dialog = true;
          }
        }
      } catch (error) {
        console.error('Error detectant comunitat:', error);
      }
    },
    selectCommunity(community) {
      if (community.id === 'catalunya') {
        this.dialog = false;
        return;
      }
      
      this.selectedCommunity = community;
      this.dialog = false;
      
      // Redirigim a sele.es amb la comunitat seleccionada
      window.location.href = `https://sele.es/selectividad/${community.id}?from=home`;
    }
  }
}
</script>

<style scoped>
.selected-community {
  background-color: #f5f5f5;
}
</style> 