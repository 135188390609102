<template>
  <v-app-bar
    color="white"
    :app="$vuetify.breakpoint.mdAndUp && $route.path !== '/dossiers-selectivitat/success'"
    height="73"
    max-height="73"
    style="z-index: 100 !important; box-shadow: #0000001f 0px 3px 6px 0px !important;"
    class="pa-0 align-center"
    :class="$vuetify.breakpoint.xs ? '' : 'px-3'"
    flat
    clipped-left
  >


    <div v-if="!onlySearch && $vuetify.breakpoint.xs">
      <v-btn icon @click="mobileDialog=true,dialogAssignatures=true"
             width="30" class="mr-2 no-background-hover"
             :ripple="false"
             aria-label="Menú"
      >
        <v-icon size="24" color="#1e3c87">{{ mdiMenu }}</v-icon>
      </v-btn>
    </div>

    <router-link
      v-if="!onlySearch"
      aria-label="Examenselectivitat"
      to="/" class="d-flex align-center">
      <v-avatar color="transparent" size="42">
        <v-img src="../../assets/logo.png"
               width="42"
               height="42"
               contain
        ></v-img>
      </v-avatar>

      <v-toolbar-title
        v-if="!onlySearch && !$vuetify.breakpoint.xs"
        style="cursor:pointer;" class="ml-3 mt-1 mr-5">
        <h1
          style="color: #1872e1; font-size: 1.2em; letter-spacing: -1px !important; font-weight: 700 !important"
          class="mb-1 font-rubik">
          <span>examenselectivitat</span>
        </h1>
      </v-toolbar-title>
    </router-link>


    <v-hover v-slot:default="{ hover }">
      <v-btn
        v-if="!onlySearch && !$vuetify.breakpoint.xs"
        @click="dialogAssignatures=!dialogAssignatures"
        text :ripple="false"
        :color="hover ? '#256EFF' : 'black'"
        style="letter-spacing: normal"
        aria-label="Exàmens"
        class="text-none font-weight-medium font-rubik no-background-hover"
      >
        Exàmens
        <v-icon
          :color="hover ? '#256EFF' : 'black'"
          :style="dialogAssignatures ? 'transform: rotate(180deg) !important' : ''"
        >{{ mdiChevronDown }}
        </v-icon>
      </v-btn>
    </v-hover>


    <v-hover v-slot:default="{ hover }">
      <v-btn
        v-if="!$vuetify.breakpoint.xs && !onlySearch"
        @click="$router.push({path: '/dossiers-selectivitat'})"
        text :ripple="false"
        :color="hover ? '#256EFF' : 'black'"
        style="letter-spacing: normal"
        aria-label="Dossiers"
        class="text-none no-background-hover mr-5 dossiers-btn"
      >
        <v-badge color="#FFA201" dot>
          <span class="font-weight-medium font-rubik">
          Dossiers
          </span>
        </v-badge>
      </v-btn>
    </v-hover>


    <v-hover v-slot:default="{ hover }">
      <v-btn
        v-if="!$vuetify.breakpoint.xs && !onlySearch"
        @click="$router.push({path: '/notes-de-tall'})"
        text :ripple="false"
        :color="hover ? '#256EFF' : 'black'"
        style="letter-spacing: normal"
        aria-label="Notes de tall"
        class="text-none no-background-hover mr-5 dossiers-btn"
      >
          <span class="font-weight-medium font-rubik">
          Notes de tall
          </span>
      </v-btn>
    </v-hover>

    <v-hover v-slot:default="{ hover }">
      <v-btn
        v-if="!$vuetify.breakpoint.xs && !onlySearch"
        @click="$router.push({path: '/calculadora-selectivitat'})"
        text :ripple="false"
        aria-label="Calculadora"
        :color="hover ? '#256EFF' : 'black'"
        style="letter-spacing: normal"
        class="text-none no-background-hover mr-5 dossiers-btn"
      >
          <span class="font-weight-medium font-rubik">
          Calculadora
          </span>
      </v-btn>
    </v-hover>


    <v-hover
      v-if="false"
      v-slot:default="{ hover }">
      <v-btn
        @click="horariDialog=!horariDialog"
        text :ripple="false"
        :color="hover ? '#256EFF' : 'black'"
        style="letter-spacing: normal"
        aria-label="Horaris"
        class="text-none font-weight-medium font-rubik no-background-hover"
      >
        Horaris
        <v-icon
          :color="hover ? '#256EFF' : 'black'"
          :style="horariDialog ? 'transform: rotate(180deg) !important' : ''"
        >{{ mdiChevronDown }}
        </v-icon>
      </v-btn>
    </v-hover>

<!--    <horari-->
<!--      v-if="false"-->
<!--      :value.sync="horariDialog"></horari>-->


    <v-dialog v-model="dialogAssignatures"
              overlay-opacity="1"
              origin="top top 0"
              transition="scroll-y-transition"
              overlay-color="white"
              hide-overlay
              :scrollable="true"
              class="dialog-assignatures elevation-0"
              fullscreen>
      <v-card flat
              color="=white"
              tile style="border-top: thin solid #E4E6EA" class="d-flex flex-column">
        <v-btn
          v-if="!mobileDialog && $vuetify.breakpoint.xs"
          @click="mobileDialog=true"
          style="position: absolute; left: 4px; top: 47px"
          aria-label="Tornar"
          icon>
          <v-icon color="#333333">{{ mdiChevronLeft() }}</v-icon>
        </v-btn>
        <v-btn
          @click="dialogAssignatures=false"
          style="position: fixed; right: 20px; top: 86px"
          aria-label="Tancar"
          icon>
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>


        <div
          v-if="$vuetify.breakpoint.xs && mobileDialog"
          style="width: 100%; padding-left: 2.5rem; padding-right: 2.5rem"
          class="d-flex flex-column align-center justify-center mx-auto my-12">
          <div class="text-start align-start d-flex justify-start"
               style="width: 100%; height: 60px"
          >
            <h2>Menú</h2>
          </div>

          <div style="flex: 1; width: 100%" class="d-flex flex-column">
            <v-card
              @click="mobileDialog=false"
              style="width: 100%; border: thin solid #f5f5f5"
              class="d-flex shadow-sm pa-3"
              flat
            >
          <span
            style="font-size: 1rem; flex:1; line-height: 1.5rem; white-space: normal; font-weight: 500 !important; color: #4B5563"
            class="font-rubik">
                Exàmens
          </span>
              <v-icon color="#4B5563">{{ mdiChevronRight() }}</v-icon>
            </v-card>

            <v-card
              @click="$router.push({path: '/dossiers-selectivitat'}), mobileDialog=false"
              style="width: 100%; border: thin solid #f5f5f5"
              class="d-flex shadow-sm pa-3 mt-6"
              flat
            >
          <span
            style="font-size: 1rem; flex:1; line-height: 1.5rem; white-space: normal; font-weight: 500 !important; color: #4B5563"
            class="font-rubik">
                Dossiers
          </span>
              <v-icon color="#4B5563">{{ mdiChevronRight() }}</v-icon>
            </v-card>

            <v-card
              @click="mobileDialog=false, dialogAssignatures=false, $router.push({path: '/notes-de-tall'})"
              style="width: 100%; border: thin solid #f5f5f5"
              class="d-flex shadow-sm pa-3 mt-6"
              flat
            >
          <span
            style="font-size: 1rem; flex:1; line-height: 1.5rem; white-space: normal; font-weight: 500 !important; color: #4B5563"
            class="font-rubik">
                Notes de tall
          </span>
              <v-icon color="#4B5563">{{ mdiChevronRight() }}</v-icon>
            </v-card>
            <v-card
              @click="mobileDialog=false, dialogAssignatures=false, $router.push({path: '/calculadora-selectivitat'})"
              style="width: 100%; border: thin solid #f5f5f5"
              class="d-flex shadow-sm pa-3 mt-6"
              flat
            >
          <span
            style="font-size: 1rem; flex:1; line-height: 1.5rem; white-space: normal; font-weight: 500 !important; color: #4B5563"
            class="font-rubik">
                Calculadora PAU
          </span>
              <v-icon color="#4B5563">{{ mdiChevronRight() }}</v-icon>
            </v-card>
          </div>
          <v-spacer></v-spacer>

            <v-btn
            v-if="($root.currentUser === null || !$root.currentUser.premium)"
            @click="$router.push({path: '/premium'})"
            :ripple="false" outlined
            color="#256EFF"
            style="letter-spacing: normal; border-color: #FFA204 !important;"
            class="text-none font-weight-bold font-rubik mr-6 rounded-lg px-3 ml-3 mt-12"
          >
            Fes-te Premium
          </v-btn>
        </div>


        <Assignatures
          v-if="!(mobileDialog && dialogAssignatures) || !$vuetify.breakpoint.xs"
          @close="dialogAssignatures=false"
          style="background-color: white"
        ></Assignatures>
      </v-card>
    </v-dialog>

    <v-spacer
      v-if="$vuetify.breakpoint.mdAndDown"
      style="flex: 1 !important;"></v-spacer>


    <search-bar-v2
      :only-search.sync="onlySearch"
      :appbar="true"
      :style="$vuetify.breakpoint.xs || onlySearch ? 'max-width: 100%;' : 'max-width: 400px;'"></search-bar-v2>

    <v-spacer
      v-if="!onlySearch && $vuetify.breakpoint.lgAndUp"
      style="flex: 1 !important;"></v-spacer>


    <v-btn
      v-if="($root.currentUser === null || !$root.currentUser.premium) && !$vuetify.breakpoint.xs"
      @click="$router.push({path: '/premium'})"
      :ripple="false"  depressed
      color="rgb(78 106 255)"
      style="letter-spacing: normal; color: white; border: 2px solid #ffa600"
      class="text-none font-weight-bold font-rubik mr-6 rounded-lg px-3 ml-3"
    >
      Fes-te Prèmium 
      <span class="ml-1" style="font-size: 1.2rem;">✨</span>
    </v-btn>


    <v-tooltip
      v-if="!onlySearch"
      color="black"
      bottom>
      <template v-slot:activator="{ on }">
        <login-button class="ml-1" v-on="on"></login-button>
      </template>
      <span>Iniciar sessió</span>
    </v-tooltip>


  </v-app-bar>

</template>

<script>
import {mdiChevronLeft, mdiChevronRight, mdiMenu} from '/src/assets/mdi.json';
import {mdiChevronDown} from '/src/assets/mdi.json'
import {mdiClose} from '/src/assets/mdi.json'
import {mdiShoppingOutline} from '/src/assets/mdi.json'
import LoginButton from '../../views/profile/login/LoginButton'

import SearchBarV2 from '../../views/cercador/SearchBarV2'
import Assignatures from "../../views/home/components/Assignatures";
// import Horari from "./Horari.vue";

export default {
  data() {
    return {
      mdiShoppingOutline: mdiShoppingOutline,
      mdiChevronDown: mdiChevronDown,
      mdiClose: mdiClose,
      mdiMenu: mdiMenu,
      onlySearch: false,
      menu: null,
      sobreNosaltres: [
        {
          text: `Contacta'ns`,
          url: '/contacte',
        }
      ],
      dialogAssignatures: false,
      mobileDialog: false,
      horariDialog: false,
    }
  },
  props: {
    menuDrawer: Boolean
  },
  components: {
    // Horari,
    Assignatures,
    LoginButton,
    SearchBarV2
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs';
    }
  },
  methods: {
    mdiChevronLeft() {
      return mdiChevronLeft
    },
    mdiChevronRight() {
      return mdiChevronRight
    },
    goTo: function (row) {
      this.$router.push({path: `${row}`});
    }
  },
  watch: {
    menu(val) {
      this.$emit("update:menuDrawer", val)
    },
    '$route.query': {
      immediate: true,
      handler(val) {
        if (val && val.assignatures == 'true') {
          this.dialogAssignatures = true
          this.$router.replace('/')
        }
      }
    }
  }
}
</script>

<style>

.v-dialog--fullscreen {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 73px !important;
  left: 0;
  z-index: 5 !important;
}

.gradient-background {
  background: rgb(112, 152, 218);
  background: linear-gradient(302deg, rgba(112, 152, 218, 1) 0%, rgba(70, 110, 179, 1) 100%);
}


.tit {
  color: #333333;
  font-weight: 600;
  font-family: Rubik, sans-serif !important;
}

.no-background-hover::before {
  background-color: transparent !important;
}

#insta {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 200px; /* change this to change the size*/

}


.dossiers-btn .v-badge__badge {
  -webkit-animation: jello-horizontal 0.9s infinite both;
  animation: jello-horizontal 0.9s infinite both;
}

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


</style>
