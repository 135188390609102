<template>
    <v-card
        min-height="300"
        max-width="550"
        width="100%"
        class="rounded-xl pa-8 d-flex flex-column"
        :class="`${dense ? '' : 'slide-in-right'} ${flat ? '' : 'shadow-sm'}`"
        :flat="flat"
        style="background-color: white"
        color="#256EFF"
    >
        <!--              <span class="font-weight-bold font-rubik"-->
        <!--                    style="font-size: 2rem"-->
        <!--              >-->
        <!--                Premium-->
        <!--              </span>-->
        <span class="font-weight-bold font-rubik text-center" style="font-size: 1.8rem">
          Necessitem el teu suport
        </span>
        <div class="d-flex align-end mx-auto justify-start">
            <div
                class="font-rubik align-self-end text-center"
            >
                <span v-if="offerPremium" style="color: #FF3B30; font-size: 2rem; margin-right: 12px; font-weight: 400">-67%</span>
                <span style="font-size: 2.5rem; height: fit-content; color: #256EFF" class="font-weight-bold">14,99€</span>
                <span style="font-size: 12px" class="ml-1">únic pagament</span>
            </div>
        </div>
        <div v-if="offerPremium" class="font-rubik mb-3 ml-1 text-center" style="font-size: 1rem; color: #565959">
            <span>Preu habitual:</span>
            <span style="text-decoration: line-through; margin-left: 8px">14,99€</span>
            <br>

        </div>

        <countdown-timer v-if="offerPremium" class="mt-4"></countdown-timer>

        <!--        <div style="height: 48px"></div>-->


        <v-divider
            v-if="!dense"
            class="my-4"></v-divider>

        <div
            v-if="!dense"
            class="d-flex flex-column"
            style="min-height: 100px"
        >
            <!-- Títol -->

            <p style="color: #333333; font-size: 1rem; margin-top: 10px">
                Ens esforcem per oferir-te contingut gratuït i de qualitat. Els anuncis ens ajuden a mantenir la web
                activa i
                a seguir creixent.
            </p>
            <p style="color: #333333; font-size: 1rem; margin-bottom: 30px">
                Amb el pla <strong style="color: #256EFF">Premium</strong>, no només elimines els anuncis, sinó que
                contribueixes al
                manteniment i millora contínua del nostre projecte.
            </p>

            <!-- Llistat de beneficis del Premium -->
            <v-divider class="my-4"></v-divider>
            <div class="d-flex flex-column" style="min-height: 100px">
                <div class="d-flex mb-5">
                    <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>
                    <span class="font-rubik font-weight-medium">Gaudeix de la web <span style="color: #256EFF">sense publicitat</span></span>
                </div>
                <div class="d-flex mb-5">
                    <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>
                    <span class="font-rubik font-weight-medium">Ajuda a garantir el manteniment i <span
                        style="color: #256EFF">millora contínua</span></span>
                </div>
                <div class="d-flex mb-5">
                    <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>
                    <span class="font-rubik font-weight-medium">Dona suport a la creació de nous <span
                        style="color: #256EFF">continguts</span></span>
                </div>
            </div>
        </div>
        <!--            &lt;!&ndash;- Estudia sense publicitat -&ndash;&gt;-->
        <!--            <div class="d-flex mb-5">-->
        <!--                <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>-->
        <!--                <span class="font-rubik font-weight-medium">Estudia-->
        <!--                    <span style="color: #256EFF">-->
        <!--                      sense publicitat-->
        <!--                    </span>-->
        <!--                    </span>-->
        <!--            </div>-->

        <!--            &lt;!&ndash;- Control dels problemes que ja has fet -&ndash;&gt;-->
        <!--            <div-->
        <!--                class="d-flex mb-5">-->
        <!--                <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>-->
        <!--                <span class="font-rubik font-weight-medium">-->
        <!--                    <span style="color: #256EFF">-->
        <!--                      Control-->
        <!--                    </span>-->
        <!--                      dels problemes que ja has fet (pròximament)-->
        <!--                    </span>-->
        <!--            </div>-->


        <!--            &lt;!&ndash;- Guarda els teus problemes en col·leccions i comparteix-les -&ndash;&gt;-->
        <!--            <div-->
        <!--                class="d-flex mb-5">-->
        <!--                <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>-->
        <!--                <span class="font-rubik font-weight-medium">-->
        <!--                    <span style="color: #256EFF">-->
        <!--                      Guarda-->
        <!--                    </span>-->
        <!--                    els teus problemes en-->
        <!--                    <span style="color: #256EFF">-->
        <!--          col·leccions-->
        <!--                    </span>-->
        <!--                      (pròximament)-->
        <!--                    </span>-->
        <!--            </div>-->

        <!--            &lt;!&ndash;- Visualitzacions il·limitades de problemes i solucions -&ndash;&gt;-->
        <!--            <div-->
        <!--                v-if="false"-->
        <!--                class="d-flex mb-5">-->
        <!--                <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>-->
        <!--                <span class="font-rubik font-weight-medium">Visualitzacions-->
        <!--                    <span style="color: #256EFF">-->
        <!--                      il·limitades-->
        <!--                    </span>-->
        <!--                    de problemes i solucions</span>-->
        <!--            </div>-->


        <!--            &lt;!&ndash;- Guarda els teus problemes en col·leccions i comparteix-les -&ndash;&gt;-->
        <!--            <div-->
        <!--                v-if="false"-->
        <!--                class="d-flex mb-5">-->
        <!--                <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>-->
        <!--                <span class="font-rubik font-weight-medium">-->
        <!--                    <span style="color: #256EFF">-->
        <!--                      Problema del dia:-->
        <!--                    </span>-->
        <!--                    cada dia et preparem un problema-->
        <!--                    </span>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;- Modifica temes dels problemes -&ndash;&gt;-->
        <!--            <div-->
        <!--                class="d-flex mb-5">-->
        <!--                <v-icon size="17" color="#256EFF" class="mt-1 mr-3">{{ mdiCheck() }}</v-icon>-->
        <!--                <span class="font-rubik font-weight-medium">-->
        <!--            Donarem encara-->
        <!--          més-->
        <!--          <span style="color: #256EFF">-->
        <!--            importància-->
        <!--                    </span>-->
        <!--            al teu-->
        <!--          <span style="color: #256EFF">-->
        <!--            feedback-->
        <!--                  </span>-->
        <!--            per continuar millorant la web.-->
        <!--                    </span>-->
        <!--            </div>-->
        <!--        </div>-->

        <v-spacer></v-spacer>

        <v-btn
            @click="redirect(false)"
            block height="48"
            :style="$vuetify.breakpoint.xs ? 'font-size: 0.8rem !important' : 'font-size: 1.1rem !important'"
            class="justify-center text-none font-rubik font-weight-medium rounded-lg mt-6 mb-2 white--text"
            style="letter-spacing: normal !important; color: #282828"
            depressed color="#256EFF"
        >
            Obtenir ara
            <v-spacer></v-spacer>

            <v-icon>{{ mdiArrowRight() }}</v-icon>

        </v-btn>

        <div
            v-if="!dense"
            style="position: absolute; top: 200px; left: -56px"
            class="hidden-sm-and-down">
            <v-img src="https://examenselectivitat.cat:3000/api/images/other/leftPerson.png"
                   width="64" height="161"
            ></v-img>
        </div>


        <div
            v-if="!dense"
            style="position: absolute; top: 200px; right: -89px"
            class="hidden-sm-and-down">
            <v-img src="https://examenselectivitat.cat:3000/api/images/other/rightPerson.png"
                   width="112" height="179"
            ></v-img>
        </div>

        <v-dialog v-model="showLogin"
                  content-class="rounded-xl"
                  max-width="450">
            <v-card style="position: relative">
                <v-icon
                    style="position: absolute; right: 12px; top: 12px; z-index: 100"
                    @click="showLogin=false, this.$router.replace({path: '/premium'})"
                >{{ mdiClose() }}
                </v-icon>

                <div class="py-4 px-7 font-rubik">
                    <p style="color: #333333; font-size: 1rem; margin-top: 10px">
                        <b>
                            Inicia sessió per vincular el teu Premium
                        </b>
                    </p>
                    Per activar el pla Premium i vincular-lo al teu compte:
                </div>

                <v-divider></v-divider>

                <login-card redirect="/premium?obtain=true"
                            :show-logo="false"
                            :flat="true"
                ></login-card>

            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
import {mdiArrowRight, mdiCheck, mdiClose} from "/src/assets/mdi.json";
import {loadStripe} from "@stripe/stripe-js";
import LoginCard from "../../profile/login/components/LoginCard.vue";
import CountdownTimer from "../../../components/countdown/CountdownTimer.vue";

export default {
    name: "Abonaments",
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
    },
    components: {LoginCard, CountdownTimer},
    async mounted() {
        const pKey = process.env.VUE_APP_STRIPE_API_PUBLIC_KEY
        this.stripe = await loadStripe(pKey);
        if (this.$route.query.obtain == "true") {
            await this.redirect()
        }

        if (this.$route.query && this.$route.query.error == "true" && this.errorCount < 1) {
            this.errorCount++
            alert("Hi ha hagut un error amb el pagament. Posa't en contacte amb nosaltres: contacte@examenselectivitat.cat")
            await this.$router.replace({query: {error: false}})
        }
    },
    data() {
        return {
            stripe: null,
            showLogin: false,
            showAnimation: false,
            errorCount: 0,
            offerPremium: false
        }
    },
    methods: {
        mdiClose() {
            return mdiClose
        },
        onIntersect(entries) {
            if (!this.showAnimation && entries[0].isIntersecting)
                this.showAnimation = true;
        },
        redirect(anual) {
            const user = this.$root.currentUser.value;
            const isUserLogged = user !== null;

            // User is Logged
            if (isUserLogged) {

                console.log("user", user)

                // return

                const test = process.env.NODE_ENV === 'development';

                // let price = test ? "price_1Q1m02JQPD7NHKVkEwY6oryj" : "price_1Q1lpkJQPD7NHKVkRPAlmwQM";
                let price = this.offerPremium ? "price_1Q1lpkJQPD7NHKVkRPAlmwQM" : "price_1QeRdIJQPD7NHKVklSWwCKcX"

                // if (anual) {
                //   price = test ? "price_1MxdQrJQPD7NHKVkkLjesPNa" : "price_1MkpdSJQPD7NHKVk7IaiGmgi";
                // } else {
                //   price = test ? "price_1MxuBvJQPD7NHKVkJ0ZaeyRt" : "price_1MknwCJQPD7NHKVkKu8WTzTF";
                // }

                this.stripe.redirectToCheckout({
                    successUrl: (test ? 'http://localhost:8081' : 'https://examenselectivitat.cat') +
                        '/premium/success?session_id={CHECKOUT_SESSION_ID}',
                    cancelUrl: (test ? 'http://localhost:8081' : 'https://examenselectivitat.cat') +
                        '/premium?error=true',
                    lineItems: [
                        {
                            price: price,
                            quantity: 1
                        }
                    ],
                    mode: "payment",
                    customerEmail: user.user_email,
                    clientReferenceId: "cus_NiXkx5odTkuUJS"
                });
            }

            // User is not logged
            else {
                this.showLogin = true;
            }
        },
        mdiCheck() {
            return mdiCheck
        },
        mdiArrowRight() {
            return mdiArrowRight
        }
    },
    watch: {
        '$route.query': {
            immediate: true,
            async handler(val) {
                if (val.error && this.errorCount < 1) {
                    this.errorCount++
                    alert("Hi ha hagut un error amb el pagament. Posa't en contacte amb nosaltres: contacte@examenselectivitat.cat")
                }
                console.log("val", val)
                if (val.showLogin) {
                    this.showLogin = val.showLogin == "true"
                }

                if (val.obtain == "true") {
                    this.redirect(false)
                }
            }
        }
    }
}
</script>

<style scoped>
.slide-in-right {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(133px);
        transform: translateX(133px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(133px);
        transform: translateX(133px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-133px);
        transform: translateX(-133px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-133px);
        transform: translateX(-133px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

</style>
