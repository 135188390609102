<template>
  <div class="countdown-container font-rubik">
    <div class="countdown-text">
      L'oferta acaba en:
    </div>
    <div class="countdown-numbers">
      {{ days }}d {{ hours }}h {{ minutes }}m {{ seconds }}s
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timer: null
    }
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        const now = new Date().getTime()
        const reisDay = new Date('2025-01-06T23:59:59').getTime()
        console.log(reisDay)
        const distance = reisDay - now

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000)

        if (distance < 0) {
          clearInterval(this.timer)
          this.days = 0
          this.hours = 0
          this.minutes = 0
          this.seconds = 0
        }
      }, 100)
    }
  }
}
</script>

<style scoped>
.countdown-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.countdown-text {
  color: #565959;
  font-size: 0.9rem;
  margin-bottom: 4px;
}

.countdown-numbers {
  font-weight: bold;
  color: #F44336;
  font-size: 1rem;
}
</style> 